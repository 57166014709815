<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance the following redox reaction in the <b>gas phase</b>. Note that this reaction cannot
        be balanced with the half-reaction method and must be done by inspection. In the gas phase,
        <chemical-latex content="H2O(l)" />
        is used to balance oxygen atoms and
        <chemical-latex content="H3O+" /> and <chemical-latex content="OH-" />
        <b>are not</b> available for balancing purposes. ONLY integers should be used in your
        answer.
      </p>

      <p class="mb-6 pl-10">
        <chemical-latex content="NH3(aq) + O2(g) -> NO2(g)" />
      </p>

      <chemical-notation-input
        v-model="inputs.redoxReaction"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question233',
  components: {
    ChemicalLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        redoxReaction: null,
      },
    };
  },
};
</script>
